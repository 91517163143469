// require('./bootstrap');

window.addEventListener('DOMContentLoaded', event => {
    const menuBtn = document.querySelector('#header-menu .menu-btn');
    if(menuBtn) {
        menuBtn.addEventListener('click', e => {
            e.preventDefault();
            menuBtn.classList.toggle('open');
        });
    }
});
